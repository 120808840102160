import { ICallForPatientResponse } from '../../Common/interfaces';
import { Log } from '../../Common/Log';
import { Utils } from '../../Common/Utils';
import Modal from '../../Common/Modal';

export class ReceiveVideoCall {
    private consultationUid: string;
    private conferenceDataInterval: number;
    private pollingInterval: number = null;
    private hasInteractedWithCall = false;

    private phoneSound = document.getElementById(
        'phoneSound',
    ) as HTMLAudioElement;

    constructor(pollingInterval?: number) {
        const self = this;

        self.pollingInterval = pollingInterval || 10000;

        self.checkUserHasCallFromDoctor();

        self.conferenceDataInterval = window.setInterval(() => {
            self.checkUserHasCallFromDoctor();
        }, self.pollingInterval);
    }

    public acceptCall(): void {
        Log.info('ReceiveVideoCall: acceptCall');
        const self = this;
        self.hasInteractedWithCall = true;
        self.pauseSound();
        clearInterval(self.conferenceDataInterval);
        window.location.href =
            window.location.origin +
            '/consultations/' +
            self.consultationUid +
            '/videocall';
    }

    public rejectCall(): void {
        const self = this;
        self.hasInteractedWithCall = true;
        self.pauseSound();
        Modal.close('phone-answer-modal');

        Utils.ajaxCall(
            'DELETE',
            'api/video/signal/' + self.consultationUid + '/reject',
            () => {},
            () => {},
        );
    }

    public reappearRingingModal(): void {
        const self = this;

        if (!self.hasInteractedWithCall) {
            setTimeout(() => {
                Modal.show('phone-answer-modal');
            }, 1000);
        }

        self.hasInteractedWithCall = false;
    }

    private checkUserHasCallFromDoctor(): void {
        const self = this;

        const callBackAjax = (
            response: ICallForPatientResponse,
            _status: any,
            error: any,
        ): void => {
            if (response && response.ConsultationUid) {
                Log.info(
                    'checkUserHasCallFromDoctor: Showing incoming call modal',
                    response,
                );
                self.consultationUid = response.ConsultationUid;
                Modal.show('phone-answer-modal');
                self.playSound();
            } else {
                Modal.close('phone-answer-modal');
            }
        };

        Utils.ajaxCall(
            'GET',
            'api/video/signal/check-for-call',
            callBackAjax,
            () => {},
        );
    }

    private playSound(): void {
        // typically this fails because the user didn't interact with the document first
        try {
            const self = this;
            if (self.phoneSound) {
                self.phoneSound.play().catch((reason: any) => {
                    Log.info('Could not play sound. reason: ', reason);
                });
            }
        } catch (error) {
            Log.info('Could not play sound. error: ', error);
        }
    }

    private pauseSound(): void {
        const self = this;
        if (self.phoneSound) {
            self.phoneSound.pause();
        }
    }
}

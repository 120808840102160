import { format } from "@hejdoktor/resx-compiler"
const pluralRules = new Intl.PluralRules("da")

export const R = {
	"AddMore": "Tilføj flere",
	"BookTimeVideoCall": "Har du ikke tid lige nu, så find en ledig tid herunder og bestil din videokonsultation. Vi sender dig en e-mail med yderligere information inden opkaldet.",
	"BookVideoConsultation_FirstAvailableCaregiver": "Først ledige",
	"DynamicCodeSubject": "Dynamic Code recept",
	"ErrorCreateConsultation": "Der opstod en fejl under oprettelse af konsultationen",
	"ErrorFetchingTimetable": "Der opstod en fejl under hentning af tidsplan. Prøv venligst igen, eller kontakt os hvis problemet fortsætter.",
	"ErrorSchedulingConsultation": "Der opstod en fejl under planlægning af konsultationen. Prøv venligst igen, eller kontakt os hvis problemet fortsætter.",
	"ErrorSendingMessage": "Der opstod en fejl under afsendelse af besked. Prøv venligst igen, eller kontakt os hvis problemet fortsætter.",
	"FillOutMessage": "Udfyld venligst en besked.",
	"InvalidFileType": "Ugyldig filtype.",
	"InvalidPayment": "Ugyldig betaling",
	"InvalidPhoneNumber": "Ugyldigt telefonnummer",
	"MaxFilesExceeded": "Du kan ikke tilføje flere filer.",
	"NoDoctorsAvailableForQueueScheduleMessage": "Find en ledig tid herunder og bestil din videokonsultation. Vi sender dig en e-mail med yderligere information inden opkaldet.",
	"PayButton": "Gå til betaling",
	"PhoneNumberRequired": "Angiv venligst telefonnummer. Så kan vi få fat på dig, hvis teknikken driller.",
	"PleaseAttachAnImageOfTheProblem": "Tilføj venligst et billede der viser problemet",
	"PleaseChooseDate": "Vælg dato for videokonsultation",
	"SendButton": "Send",
	"SubjectRequired": "Emne skal udfyldes",
	"TimeSlotUnavailable": "Tidspunktet er desværre allerede booket, vælg et andet tidspunkt.",
	"TimeZoneWarning": "Tidspunkterne vises i din lokale tidszone: {0}",
	"Today": "I dag",
	"TooLateToReschedule": "Det er desværre ikke længere muligt at ændre tidspunktet for konsultationen.",
	"UploadFailedTooLarge": "Filen \"{0}\" er for stor, og kan derfor ikke uploades. Tjek venligst at din fil ikke er større end 30 MB.",
	"WaitingTimeText": (number: number, { minimumFractionDigits = 0, maximumFractionDigits = 3 }: { minimumFractionDigits?: number, maximumFractionDigits?: number } = {}) => {
		const nf = new Intl.NumberFormat("da", { minimumFractionDigits, maximumFractionDigits })
		switch(pluralRules.select(number)) {
			case "one":
				return format("Der er i øjeblikket ca. {0} minuts ventetid.", nf.format(number))
			default:
			case "other":
				return format("Der er i øjeblikket ca. {0} minutters ventetid.", nf.format(number))
		}
	},
}
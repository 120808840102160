import { format } from "@hejdoktor/resx-compiler"
const pluralRules = new Intl.PluralRules("en")

export const R = {
	"AddMore": "Add more",
	"BookTimeVideoCall": "If you don't have time right now, find a free time below and book your video consultation. We will send you an email with further information before the call.",
	"BookVideoConsultation_FirstAvailableCaregiver": "First available",
	"DynamicCodeSubject": "Dynamic Code prescription",
	"ErrorCreateConsultation": "An error occurred while creating the consultation",
	"ErrorFetchingTimetable": "An error occurred while retrieving schedule. Please try again or contact us if the problem persists.",
	"ErrorSchedulingConsultation": "An error occurred while scheduling the consultation. Please try again or contact us if the problem persists.",
	"ErrorSendingMessage": "An error occurred while sending message. Please try again or contact us if the problem persists.",
	"FillOutMessage": "Please fill in a message",
	"InvalidFileType": "Invalid file type.",
	"InvalidPayment": "Invalid payment",
	"InvalidPhoneNumber": "Invalid phone number",
	"MaxFilesExceeded": "You can't add more files.",
	"NoDoctorsAvailableForQueueScheduleMessage": "Find an available time below and book your video consultation. We will send you an email with further information before the call.",
	"PayButton": "Go to payment",
	"PhoneNumberRequired": "Please provide a phone number. We will use this to get in touch with you in case of tehcnical issues.",
	"PleaseAttachAnImageOfTheProblem": "Please attach an image of the problem",
	"PleaseChooseDate": "Select date for video consultation",
	"SendButton": "Send",
	"SubjectRequired": "Subject is required",
	"TimeSlotUnavailable": "Unfortunately, the time is already booked, choose another time.",
	"TimeZoneWarning": "The times are shown in your local timezone: {0}",
	"Today": "Today",
	"TooLateToReschedule": "Unfortunately, it is no longer possible to reschedule the consultation.",
	"UploadFailedTooLarge": "The file \"{0}\" is too large and therefore cannot be uploaded. Please check that your file is not larger than 30 MB.",
	"WaitingTimeText": (number: number, { minimumFractionDigits = 0, maximumFractionDigits = 3 }: { minimumFractionDigits?: number, maximumFractionDigits?: number } = {}) => {
		const nf = new Intl.NumberFormat("en", { minimumFractionDigits, maximumFractionDigits })
		switch(pluralRules.select(number)) {
			case "one":
				return format("Waiting time is around {0} minute.", nf.format(number))
			default:
			case "other":
				return format("Waiting time is around {0} minutes.", nf.format(number))
		}
	},
}
import { format } from "@hejdoktor/resx-compiler"
export { format }
import { getLocale } from "@hejdoktor/resx-compiler"

import { R as _da } from "./ConsultationResources.da.resx"
import { R as _en } from "./ConsultationResources.en.resx"

type Translations = {
	"AddMore": string,
	"BookTimeVideoCall": string,
	"BookVideoConsultation_FirstAvailableCaregiver": string,
	"DynamicCodeSubject": string,
	"ErrorCreateConsultation": string,
	"ErrorFetchingTimetable": string,
	"ErrorSchedulingConsultation": string,
	"ErrorSendingMessage": string,
	"FillOutMessage": string,
	"InvalidFileType": string,
	"InvalidPayment": string,
	"InvalidPhoneNumber": string,
	"MaxFilesExceeded": string,
	"NoDoctorsAvailableForQueueScheduleMessage": string,
	"PayButton": string,
	"PhoneNumberRequired": string,
	"PleaseAttachAnImageOfTheProblem": string,
	"PleaseChooseDate": string,
	"SendButton": string,
	"SubjectRequired": string,
	"TimeSlotUnavailable": string,
	"TimeZoneWarning": string,
	"Today": string,
	"TooLateToReschedule": string,
	"UploadFailedTooLarge": string,
	"WaitingTimeText": (number: number, options?: { minimumFractionDigits?: number, maximumFractionDigits?: number }) => string,
}

const R = new Proxy({}, {
	get(target, name: keyof Translations, receiver) {
		if(process.env.enforcedLocale === "da" || (!process.env.enforcedLocale && getLocale("da") === "da")) {
			return _da[name] ?? name
		}

if(process.env.enforcedLocale === "en" || (!process.env.enforcedLocale && getLocale("da") === "en")) {
			return _en[name] ?? name
		}

		return name
	},
}) as Translations

export { R }